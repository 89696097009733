@import "../themes.scss";

.main {
    position: relative;
    width: 100%;
    background-color: #e3f2fd;
  }
  
  .inner {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #e3f2fd;
    display: table;
    overflow-x: auto;
    white-space: nowrap;
  
    @include mobile-small {
      width: 320px !important;
    }
    @include mobile {
      width: 480px !important;
    }
    @include mobile-large {
      width: 550px !important;
    }
    @include tablet {
      width: 768px !important;
    }
  }
  
  .backdrop-style {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 30%,
      rgba(221, 233, 243, 1) 100%
    );
  
    .background-image {
      // background-image: url("../Assets/backgroundImages.png");
      background-repeat: no-repeat;
      background-size: 80%;
      background-position: 50% 50%;
      position: fixed;
      opacity: 0.1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  
    .system-title {
      margin-top: 10px;
      margin-bottom: 20px;
      color: $secondary-color;
      font-size: 20px;
      font-weight: bold;
    }
  
    .power-title {
      font-weight: bold;
      color: $secondary-color;
    }
  }

  .outlet-container {
    background-repeat: no-repeat;
    background-image: url("../Assets/angkor-picture.png");
    background-size: 1200px;
    background-position: 45% 20%;
    height: 100vh;
    // background-color: gray;
  }
@import "../../../themes.scss";

.view-customer {
  // .MuiPaper-root {
  //   border-radius: 12px !important;
  // }

  .titleKh {
    font-family: $font-body;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
  }

  .titleEn {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
  }
  .clear-icon {
    color: red;
  }

  .constainer {

    .tage-center {
      text-align: center;
      color: $primary-color;
      font-weight: bold;
      font-size: $font-Size-title;
      font-family: $font-title;
    }
    .table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0px 8px;

      .table-head {
        .table-row {
          .table-cellKh {
            color: $primary-color;
            font-weight: bold;
            padding: 12px;
            font-size: $font-Size-Subtitle;
            border: none;
            font-family: $font-body;
          }
          .table-cellEn {
            color: $primary-color;
            font-weight: bold;
            padding: 12px;
            font-size: $font-Size-Subtitle;
            border: none;
            font-family: $font-title;
          }

        }
      }

      .table-body {
        box-shadow: none;
        padding: 20px;
        background-color: $background-table-active;

        .body-row {
          .body-cell {
            padding: 10px;
            border: none;
            font-size: $font-Size-Subtitle;
            font-family: $font-title;
          }
          .body-cell-start {
            padding: 10px;
            border: none;
            border-radius: 5px 0px 0px 5px;
            font-size: $font-Size-Subtitle;
            font-family: $font-title;
          }
          .body-cell-end {
            padding: 10px;
            border: none;
            border-radius: 0px 5px 5px 0px;
            font-size: $font-Size-Subtitle;
            font-family: $font-title;
          }
        }
      }
    }
  }
}

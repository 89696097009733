@import "../themes.scss";

.forgot-page {
  width: 100%;
  height: 100vh;
  position: fixed;
  // background-color: $background-menu;

  .background-image {
    background-image: url("../Assets/backgroundImages.png");
    background-repeat: no-repeat;
    // background-size: 80%;
    background-size: 50%;
    background-position: 50% 50%;
    position: fixed;
    opacity: 0.2;
    filter: blur(1px);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .text-field {
    background-color: #fff;
    border-radius: 6px;

    .MuiOutlinedInput-input {
      color: black !important;
    }
    .MuiInputLabel-root {
      color: black !important;
    }
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
    }
    :hover .MuiOutlinedInput-input {
      color: #fff;
    }
    :hover .MuiInputLabel-root {
      color: black;
    }
    :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
    }
    .MuiInputLabel-root.Mui-focused {
      color: black;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
    }
  }

  .back-btn {
    height: 10%;

    .text-back {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title-back {
        color: #000;
        text-transform: none;
      }
    }
  }

  .container {
    width: 100%;
    height: 85%;
    // position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .box-text {
      display: flex;
      justify-content: center;

      .title-login {
        .title {
          color: #000;
          font-weight: bold;

          @include mobile-large {
            font-size: 14px;
            font-weight: 500;
          }
        }

        .sub-title {
          color: #000;
        }
      }
    }

    .box-login {
      display: flex;
      justify-content: center;

      .icon {
        color: $primary-color;
      }

      .btb-sign-in {
        background-color: #fff !important;
        color: $primary-color;
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
}

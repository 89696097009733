@import "../themes.scss";


.update-container {
  width: 28px;
  height: 28px;
  border-radius: 5px;
  background-color: #ffab00 !important;
  .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
    padding: 6px;
  }

  .update-icon {
    color: white !important;
    font-size: 18px !important;
  }
}
.approve-container {
  width: 28px;
  height: 28px;
  border-radius: 5px;
  background-color: $primary-color !important;

  .approve-icon {
    color: white !important;
    font-size: 18px !important;
  }
}
.reject-container {
  width: 28px;
  height: 28px;
  border-radius: 5px;
  background-color: #af0000 !important;

  .reject-icon {
    color: white !important;
    font-size: 18px !important;
  }
}

.delete-container {
  width: 28px;
  height: 28px;
  border-radius: 5px;
  background-color: red !important;

  .delete-icon {
    color: white !important;
    font-size: 18px !important;
  }
}
.cmt-container {
  width: 28px;
  height: 28px;
  border-radius: 5px;
  background-color: #ff7400 !important;

  .cmt-icon {
    color: white !important;
    font-size: 18px !important;
  }
}

@import "../../../themes.scss";

.create-user {
  .MuiPaper-root {
    border-radius: 12px !important;
    width: 500px !important;
  }

  .dialog-titleKh {
    color: $primary-color;
    font-size: $font-Size-title;
    font-family: $font-body;
    font-weight: bold;
  }

  .dialog-titleEn {
    color: $primary-color;
    font-size: $font-Size-title;
    font-family: $font-title;
    font-weight: bold;
  }
  .close-icon {
    color: red;
  }

  .titleKh {
    font-family: $font-body;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
  }

  .titleEn {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
  }

  .grid-profile {
    .profile {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .avater-image {
        width: 140px;
        height: 140px;
      }
    }
    .text-Kh {
      font-family: $font-body;
      font-size: $font-Size-Subtitle;
      font-weight: bold;
      color: $primary-color;
    }

    .text-En {
      font-family: $font-title;
      font-size: $font-Size-Subtitle;
      font-weight: bold;
      color: $primary-color;
    }
  }

  .subKh {
    font-family: $font-body;
    font-size: $font-Size-Subtitle;
    // font-weight: bold;
    color: #000;
  }

  .subEn {
    font-family: $font-title;
    font-size: $font-Size-Subtitle;
    // font-weight: bold;
    color: #000;
  }
  .action {
    padding: 0px 16px 16px 16px;
    width: 100%;
    .btn-actionKh {
      width: 100%;
      background-color: $primary-color;
      color: #fff;
      font-family: $font-body;
    }

    .btn-actionEn {
      width: 100%;
      background-color: $primary-color;
      color: #fff;
      font-family: $font-title;
    }
  }
}

@import "../../../themes.scss";

.loan-request-details {
  .slash {
    width: 4px;
    height: 50px;
    background-color: $primary-color;
  }

  .link-under {
    text-decoration: none;
    cursor: pointer;
  }
  .color {
    color: $primary-color;
    font-size: 22px;
    font-weight: bold;
    font-family: $font-title;
  }

  .btn-print {
    background-color: $primary-color !important;
    border-radius: 10px;
    padding: 8px 20px;
    color: #fff;
  }
  .filter-title {
    // color: $primary-color;
    font-size: $font-Size-Subtitle;
    font-weight: bold;
    font-family: $font-body;
  }
  .container-textField {
    display: flex;
    align-items: center;
    width: 300px;
    border-radius: 10px;
    background-color: #fff;

    @include tablet {
      width: 80%;
    }
    @include mobile-large {
      width: 100%;
    }
    @include mobile {
      width: 100%;
    }
    @include mobile-small {
      width: 100%;
    }

    .text-field {
      .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
        font-family: $font-body;
      }

      .MuiOutlinedInput-input {
        border-radius: 10px;
        font-family: $font-body;
      }

      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
        font-family: $font-body;
        border-radius: 10px;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
        color: black;
        font-family: $font-body;
        border-radius: 10px;
      }

      .MuiInputLabel-root.Mui-focused {
        color: #fff;
        font-family: $font-body;
        border-radius: 10px;
      }
    }
  }
  .box-container {
    margin-top: 20px;
    background-color: rgb(235, 235, 235);
    border-radius: 10px;
    display: flex;

    .box-container-top {
      width: 100%;
      padding: 30px 20px;
      .box-info {
        display: flex;
        justify-content: center;
        .image {
          width: 120px;
          height: 120px;
        }
      }

      .body-name {
        text-align: center;
        font-family: $font-title;
        font-size: $font-Size-title;
      }

      .body-phone {
        text-align: center;
        font-family: $font-title;
        font-size: $font-Size-Subtitle;
      }

      .body-skeleton {
        display: flex;
        justify-content: center;
      }

      .grid-details-container {
        margin-top: 50px;
        @include laptop {
          margin-top: 10px;
        }
        @include tablet {
          margin-top: 10px;
        }
        @include mobile-large {
          margin-top: 10px;
        }
        @include mobile {
          margin-top: 10px;
        }
        @include mobile-small {
          margin-top: 10px;
        }

        .details-body {
          font-size: $font-Size-Subtitle;
          font-family: $font-title;
        }
        .details-body-bold {
          font-weight: bold;
          font-family: $font-title;
        }
        .details-body-primary {
          font-weight: bold;
          color: $primary-color;
          font-family: $font-title;
        }
      }
    }

    .tag-upload {
      padding: 0px 18px 16px 18px;

      .field-header {
        font-size: $font-Size-Subtitle;
        font-weight: bold;
        font-family: $font-title;
      }
    }
  }

  .constainer {
    margin-top: 20px;

    .tage-center {
      text-align: center;
      color: $primary-color;
      font-weight: bold;
      font-size: $font-Size-title;
      font-family: $font-title;
    }
    .table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0px 8px;

      .table-head {
        .table-row {
          .table-cell {
            color: $primary-color;
            font-weight: bold;
            padding: 12px;
            font-size: $font-Size-Subtitle;
            border: none;
            font-family: $font-title;
          }
        }
      }

      .table-body {
        box-shadow: none;
        background-color: #fff;
        // :hover {
        //   cursor: pointer;
        //   background-color: $background-table-active;
        // }

        .body-row {
          .body-cell {
            padding: 10px;
            border: none;
            font-size: $font-Size-Subtitle;
            font-family: $font-title;
          }
          .body-cell-start {
            padding: 10px;
            border: none;
            border-radius: 5px 0px 0px 5px;
            font-size: $font-Size-Subtitle;
            font-family: $font-title;
          }
          .body-cell-end {
            padding: 10px;
            border: none;
            border-radius: 0px 5px 5px 0px;
            font-size: $font-Size-Subtitle;
            font-family: $font-title;
          }
        }
      }
    }
  }

  .div-container-print {
    width: 70%;
    @include desktop {
      width: 80%;
    }

    @include laptop {
      width: 85%;
    }

    @include tablet {
      width: 90%;
    }
    @include mobile-large {
      width: 90%;
    }

    @include mobile {
      width: 90%;
    }

    @include mobile-small {
      width: 90%;
    }
  }
}

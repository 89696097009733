@import ".././themes.scss";

.setting-page {
  .slash {
    width: 4px;
    height: 50px;
    background-color: $primary-color;
  }
  .titleKh {
    font-family: $font-body;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
  }

  .titleEn {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
  }

  .setting-card-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 3px;
    cursor: pointer;

    .setting-icon {
      color: $primary-color;
      width: 48px;
      height: auto;
    }

    .title-txt {
      font-family: $font-title;
      font-size: $font-Size-head;
      color: $primary-color;
      font-weight: bold;
    }
    .title-txt-kh {
      font-family: $font-body;
      font-size: $font-Size-head;
      color: $primary-color;
      font-weight: bold;
    }
    .subtitle-txt {
      font-family: $font-title;
      font-size: $font-Size-body;
    }
    .subtitle-txt-kh {
      font-family: $font-body;
      font-size: $font-Size-body;
    }
  }
}

@import ".././themes.scss";

.loanplan-page {
  .slash {
    width: 4px;
    height: 50px;
    background-color: $primary-color;
  }

  .titleKh {
    font-family: $font-body;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
  }

  .titleEn {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
  }

  .slash-title {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
  }

  .page-link-title {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-Size-title;
    text-decoration: none;
    cursor: pointer;
  }

  .btn-addKh {
    border-radius: 10px;
    background-color: $primary-color !important;
    padding: 8px 20px;
    color: #fff;
    font-family: $font-body;
  }

  .btn-addEn {
    border-radius: 10px;
    background-color: $primary-color !important;
    padding: 8px 20px;
    color: #fff;
    font-family: $font-title;
  }

  .box-marker {
    margin-top: 6px;

    .content {
      font-weight: bold;
      font-size: $font-Size-Subtitle;
    }

    .stack-controll {
      width: 300px;

      .MuiOutlinedInput-input {
        color: black !important;
        font-family: $font-body;
      }
    }

    .text-field {
      background-color: #fff;
      border-radius: 10px;

      .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
        font-family: $font-body;
      }

      .MuiOutlinedInput-input {
        border-radius: 10px;
        font-family: $font-body;
      }

      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
        font-family: $font-body;
        border-radius: 10px;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
        color: black;
        font-family: $font-body;
        border-radius: 10px;
      }

      .MuiInputLabel-root.Mui-focused {
        color: #fff;
        font-family: $font-body;
        border-radius: 10px;
      }
    }
  }

  .container {
    margin-top: 20px;

    .table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0px 8px;

      .header-row {
        .header-titleKh {
          padding: 10px;
          border: none;
          font-size: $font-Size-Subtitle;
          font-weight: bold;
          color: $primary-color;
          font-family: $font-body;
        }

        .header-titleEn {
          padding: 10px;
          border: none;
          font-size: $font-Size-Subtitle;
          font-weight: bold;
          color: $primary-color;
          font-family: $font-title;
        }
      }

      .body {
        box-shadow: none;
        background-color: #fff;

        // :hover {
        //   cursor: pointer;
        //   background-color: $background-table-active;
        // }

        .body-row {
          cursor: pointer;
          .body-cellKh {
            padding: 10px;
            border: none;
            font-family: $font-body;
            font-size: $font-Size-Subtitle;

            .text-ownerKh {
              font-family: $font-body;
              font-size: $font-Size-Subtitle;
            }
          }

          .body-cellEn {
            padding: 10px;
            border: none;
            font-family: $font-title;
            font-size: $font-Size-Subtitle;

            .text-ownerEn {
              font-family: $font-title;
              font-size: $font-Size-Subtitle;
            }
          }

          .body-cell-startKh {
            padding: 10px;
            border: none;
            font-family: $font-body;
            border-radius: 5px 0px 0px 5px;
            font-size: $font-Size-Subtitle;
          }

          .body-cell-startEn {
            padding: 10px;
            border: none;
            font-family: $font-title;
            border-radius: 5px 0px 0px 5px;
            font-size: $font-Size-Subtitle;
          }

          .body-cell-end {
            padding: 10px;
            border: none;
            border-radius: 0px 5px 5px 0px;
            font-size: $font-Size-Subtitle;
            .css-170alss-MuiStack-root{
              .css-78trlr-MuiButtonBase-root-MuiIconButton-root{
                padding: 6px;
              }
            }
          }
        }
        .body-row:hover {
          background-color: $background-table-active !important;
          transition: all 0.1s linear;
        }
      }
    }
  }
}

@import "../../../themes.scss";

.setup-customerdetails {
  .slash {
    width: 4px;
    height: 50px;
    background-color: $primary-color;
  }

  .linkKh {
    font-family: $font-body;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
    text-decoration: none;
  }

  .linkEn {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
    text-decoration: none;
  }

  .titleKh {
    font-family: $font-body;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
    text-align: left;
    padding: 0px;
  }

  .titleEn {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
    text-align: left;
    padding: 0px;
  }

  .btn-create-Kh {
    padding: 10px 20px;
    text-align: center;
    border-radius: 10px;
    background-color: $primary-color !important;
    color: #fff;
    font-family: $font-body;
  }
  .btn-create-En {
    padding: 10px 20px;
    text-align: center;
    border-radius: 10px;
    background-color: $primary-color !important;
    color: #fff;
    font-family: $font-title;
  }

  .profile-container {
    margin-top: 20px;
    // background-color: #fff;
    border-radius: 10px;

    .profile-image {
      width: 150px;
      height: 150px;
    }
    .custmer-name {
      font-size: $font-Size-title;
    }
    .custmer-info-text {
      font-size: $font-body;
    }
  }

  .tabs-container {
    background-color: #fff;
    margin: 20px 0px;

    .tabs-btn {
      width: 100%;
      height: 45px;
      text-align: center;
      text-transform: none;
      color: #000;
      font-size: $font-Size-Subtitle;
    }

    .tabs-btn-active {
      width: 100%;
      height: 45px;
      text-align: center;
      text-transform: none;
      background-color: $primary-color;
      color: #fff;
      font-size: $font-Size-Subtitle;
    }
  }

  .box-container {
    margin-top: 20px;
    background-color: rgb(235, 235, 235);
    border-radius: 10px;
    width: 100%;
    .box-container-top {
      padding: 30px 20px;
      //   background-color: red;
      .box-info {
        display: flex;
        justify-content: center;
        .image {
          width: 120px;
          height: 120px;
        }
      }

      .body-nameKh {
        text-align: center;
        font-family: $font-body;
        font-size: $font-Size-title;
      }

      .body-nameEn {
        text-align: center;
        font-family: $font-title;
        font-size: $font-Size-title;
      }

      .body-phoneKh {
        text-align: center;
        font-family: $font-title;
        font-size: $font-Size-Subtitle;
        font-size: $font-Size-title;
      }

      .body-phoneEn {
        text-align: center;
        font-family: $font-title;
        font-size: $font-Size-Subtitle;
        font-size: $font-Size-title;
      }

      .grid-details-container {
        @include laptop {
          margin-top: 10px;
        }
        @include tablet {
          margin-top: 10px;
        }
        @include mobile-large {
          margin-top: 10px;
        }
        @include mobile {
          margin-top: 10px;
        }
        @include mobile-small {
          margin-top: 10px;
        }

        .details-bodyKh {
          font-size: $font-Size-Subtitle;
          font-family: $font-body;
        }

        .details-bodyEn {
          font-size: $font-Size-Subtitle;
          font-family: $font-title;
        }

        .details-body-bold-Kh {
          font-weight: bold;
          font-family: $font-body;
          color: $primary-color;
        }
        .details-body-bold-En {
          font-weight: bold;
          font-family: $font-title;
          color: $primary-color;
        }

        .details-body-active {
          font-weight: bold;
          font-family: $font-title;
          color: $primary-color;
        }

        .details-body-late {
          font-weight: bold;
          font-family: $font-title;
          color: red;
        }

        .details-body-primary {
          font-weight: bold;
          color: $primary-color;
          font-family: $font-title;
        }
      }
    }
  }
}

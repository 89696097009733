@import "../../themes.scss";

.dashboard-new {
  .text-field {
    background-color: #fff;
    border-radius: 10px;

    .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
      font-family: $font-title;
    }

    .MuiOutlinedInput-input {
      border-radius: 10px;
      font-family: $font-title;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      font-family: $font-title;
      border-radius: 10px;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
      font-family: $font-title;
      border-radius: 10px;
    }

    .MuiInputLabel-root.Mui-focused {
      color: #fff;
      font-family: $font-title;
      border-radius: 10px;
    }
  }

  .slash {
    width: 4px;
    height: 50px;
    background-color: $primary-color;
  }
  .titleKh {
    font-family: $font-body;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
  }

  .titleEn {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
  }

  .container {
    margin-top: 20px;
  }
}

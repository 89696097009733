@import "../../themes.scss";

.drawer-menu {
  .MuiDrawer-paper {
    // background: $background-menu;
    border: none;
    box-shadow: 0px 15px 15px rgba(42, 173, 254, 0.25);
    width: 280px;
  }
  .css-6f328d-MuiDrawer-docked {
    .MuiDrawer-paper {
      width: 280px;
    }
  }

  .list {
    .list-item {
      .list-item-button {
        max-height: 50px;
        justify-content: initial;

        .list-item-icon {
          max-width: 35px;
          margin-right: 10px;
          justify-content: center;
          opacity: 0.5;
        }

        .list-item-text {
          color: #000;
          font-size: $font-Size-head;
        }
      }
    }

    .list-item-active {
      .list-item-button {
        max-height: 50px;
        justify-content: initial;
        // background-color: #fff;

        .list-item-icon {
          max-width: 35px;
          margin-right: 10px;
          justify-content: center;
          opacity: 0.5;
          color: $primary-color;
        }

        .list-item-text {
          color: $primary-color;
          font-size: $font-Size-head;
        }
      }
    }
  }
}

@import "../themes.scss";

.dashboard-page {
  .text-field {
    .MuiOutlinedInput-input {
      color: black !important;
    }
    .MuiInputLabel-root {
      color: black !important;
    }
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
    }
    :hover .MuiOutlinedInput-input {
      color: #fff;
    }
    :hover .MuiInputLabel-root {
      color: black;
    }
    :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
    }
    .MuiInputLabel-root.Mui-focused {
      color: black;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
    }
  }

  .slash {
    width: 4px;
    height: 50px;
    background-color: $primary-color;
  }
  .titleKh {
    font-family: $font-body;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
  }

  .titleEn {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
  }

  .make {
    align-items: center;
    justify-content: flex-start;
    direction: row;
  }

  .container {
    margin-top: 20px;
    .box-style {
      // background-color:#e5e5e9;
      border-radius: 10px;
      padding: 20px;
      color: #fff;
      .icon {
        color: #fff;
        font-size: 50px;
      }
      .icon-group {
        // color: $secondary-color;
        color: #fff;
        font-size: 50px;
      }
      .icon-loan {
        // color: $primary-color;
        color: #fff;
        font-size: 50px;
      }
      .icon-pawn {
        // color: red;
        color: #fff;
        font-size: 50px;
      }
      .icon-install {
        // color: orange;
        color: #fff;
        font-size: 50px;
      }
      .num-customer {
        font-weight: bold;
        font-size: $font-Size-title;
        font-family: $font-title;
      }
      .textKh {
        font-size: 16px;
        font-family: $font-kh-bayon;
      }
      .textEn {
        font-size: 16px;
        font-family: $font-title;
        font-weight: bold;
      }
      .tage-amount {
        font-size: 28px;
        font-family: $font-title;
      }
    }

    .box-balance {
      background-color: #fff;
      border-radius: 10px;
      padding: 20px;

      .title {
        font-size: $font-Size-title;
        font-family: $font-title;
        font-weight: bold;
      }
      .sub-title {
        color: gray;
        font-size: $font-Size-body;
      }
      .icon-balance {
        color: $primary-color;
        font-size: 50px;
      }
      .mark-balance {
        border-radius: 10px;
        padding: 5px;
        .loan-amount {
          background-color: $background-table-active;
          border-radius: 5px;
          padding: 5px;
          font-size: $font-Size-body;
        }
      }
      .box-card {
        background-color: $secondary-color;
        padding: 20px;
        border-radius: 10px;

        .tage-card {
          color: #fff;
          font-size: 30px;
          font-weight: bold;
          font-family: $font-title;
        }
        .sub-title {
          color: $background-table-active;
          font-size: $font-Size-body;
        }
      }
    }
    .content-customer {
      background-color: #fff;
      border-radius: 10px;
      padding: 20px;
      .title {
        font-size: $font-Size-title;
        font-family: $font-title;
        font-weight: bold;
      }
      .table-row {
        .table-cell {
          border-bottom: none;
          .name-customer {
            place-self: center;
          }
        }
      }
      .sub-title {
        color: gray;
        font-size: $font-Size-body;
        text-align: left;
      }
    }
    .content-loanlist {
      background-color: #fff;
      border-radius: 10px;
      padding: 20px;

      .title-graph {
        color: $primary-color;
        font-size: 24px;
        font-weight: bold;
        font-family: $font-title;
      }
    }
  }
}

@import "../../../themes.scss";

.table-container {
  margin-top: 10px;
  width: 100%;

  .loan-history-titleKh {
    margin-top: 10px;
    text-align: center;
    font-family: $font-body;
    font-size: $font-Size-title;
    color: $primary-color;
    font-weight: bold;
  }

  .loan-history-titleEn {
    margin-top: 10px;
    text-align: center;
    font-family: $font-title;
    font-size: $font-Size-title;
    color: $primary-color;
    font-weight: bold;
  }

  .table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 8px;

    .header-row {
      .header-titleKh {
        padding: 10px;
        border: none;
        font-family: $font-body;
        font-size: $font-Size-Subtitle;
        font-weight: bold;
        color: $primary-color;
      }

      .header-titleEn {
        padding: 10px;
        border: none;
        font-family: $font-title;
        font-size: $font-Size-Subtitle;
        font-weight: bold;
        color: $primary-color;
      }
    }

    .body {
      box-shadow: none;
      background-color: #fff;
      // :hover {
      //   cursor: pointer;
      //   background-color: $background-table-active;
      // }

      .body-row {
        .body-cell {
          padding: 15px 10px;
          border: none;
          font-size: $font-Size-Subtitle;
        }
        .body-cell-start {
          padding: 15px 10px;
          border: none;
          border-radius: 5px 0px 0px 5px;
          font-size: $font-Size-Subtitle;
        }
        .body-cell-end {
          padding: 15px 10px;
          border: none;
          border-radius: 0px 5px 5px 0px;
          font-size: $font-Size-Subtitle;
        }
      }
    }
  }
}

@import "../../../themes.scss";

.customer-info {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 30px 30px 30px;

  .body-textKh {
    font-family: $font-body;
    font-size: $font-Size-Subtitle;
    margin-top: 10px;
  }

  .body-textEn {
    font-family: $font-title;
    font-size: $font-Size-Subtitle;
    margin-top: 10px;
  }

  .body-item {
    font-family: $font-title;
    font-size: $font-Size-Subtitle;
    margin-top: 10px;
  }
  
  .grid-details-container {
    @include laptop {
      margin-top: 10px;
    }
    @include tablet {
      margin-top: 10px;
    }
    @include mobile-large {
      margin-top: 10px;
    }
    @include mobile {
      margin-top: 10px;
    }
    @include mobile-small {
      margin-top: 10px;
    }

    .details-bodyKh {
      font-size: $font-Size-Subtitle;
      font-family: $font-body;
    }

    .details-bodyEn {
      font-size: $font-Size-Subtitle;
      font-family: $font-title;
    }

    .details-body-bold-Kh {
      font-weight: bold;
      font-family: $font-body;
      color: $primary-color;
    }
    .details-body-bold-En {
      font-weight: bold;
      font-family: $font-title;
      color: $primary-color;
    }

    .details-body-active {
      font-weight: bold;
      font-family: $font-title;
      color: $primary-color;
    }

    .details-body-late {
      font-weight: bold;
      font-family: $font-title;
      color: red;
    }

    .details-body-primary {
      font-weight: bold;
      color: $primary-color;
      font-family: $font-title;
    }
  }
}

@import "../../../themes.scss";

.loanlist-action {
  .MuiPaper-root {
    border-radius: 12px !important;
    width: 400px !important;
  }
  .icon-menu-delete {
    color: red;
  }
  .icon-menu-update {
    color: $primary-color;
  }
  .text-menuKh {
    font-size: $font-Size-Subtitle;
    font-family: $font-body;
  }

  .text-menuEn {
    font-size: $font-Size-Subtitle;
    font-family: $font-title;
  }

  .dialog-titleKh {
    color: $primary-color;
    font-size: $font-Size-title;
    font-family: $font-body;
    font-weight: bold;
  }

  .dialog-titleEn {
    color: $primary-color;
    font-size: $font-Size-title;
    font-family: $font-title;
    font-weight: bold;
  }

  .close-icon {
    color: red;
  }

  .action {
    padding-bottom: 18px;
    padding-left: 16px;
    padding-right: 16px;
    .btn {
      border: none;
      .suspendKh {
        font-size: $font-Size-Subtitle;
        font-family: $font-body;
        border: none;
        color: #fff;
      }
      .suspendEn {
        font-size: $font-Size-Subtitle;
        font-family: $font-title;
        border: none;
        color: #fff;
      }
    }
  }
}
.three-point {
  font-weight: bold;
  color: #000;
}

.simple-textKh {
  font-size: $font-Size-Subtitle;
  font-family: $font-body;
}

.simple-textEn {
  font-size: $font-Size-Subtitle;
  font-family: $font-title;
}

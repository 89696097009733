@import "../../../themes.scss";

.reference-list {
  background-color: $background-table-active;
  border-radius: 10px;
  padding: 10px;
  .profile {
    .image {
      width: auto;
      height: 140px;
    }
  }
}

@import "../../../themes.scss";

.update-installment {
  .dialog-titleKh {
    color: $primary-color;
    font-size: $font-Size-title;
    font-family: $font-body;
    font-weight: bold;
  }

  .dialog-titleEn {
    color: $primary-color;
    font-size: $font-Size-title;
    font-family: $font-title;
    font-weight: bold;
  }

  .close-icon {
    color: red;
  }

  .field-titleKh {
    font-size: $font-Size-Subtitle;
    font-family: $font-body;
    // font-weight: bold;
  }

  .field-titleEn {
    font-size: $font-Size-Subtitle;
    font-family: $font-title;
    // font-weight: bold;
  }

  .field-title-total {
    font-size: $font-Size-Subtitle;
    font-family: $font-title;
    font-weight: bold;
    color: $primary-color;
  }

  .btn-calculateKh {
    margin-top: 24px;
    background-color: $primary-color !important;
    color: #fff;
    border-radius: 5px;
    padding: 6px 20px;
    text-transform: none;
    font-family: $font-body;
  }

  .btn-calculateEn {
    margin-top: 24px;
    background-color: $primary-color !important;
    color: #fff;
    border-radius: 5px;
    padding: 6px 20px;
    text-transform: none;
    font-family: $font-title;
  }

  .box-action {
    width: 100%;
    justify-self: right;
    padding: 0px 18px 16px 18px;
    .btn-createKh {
      text-align: center;
      width: 100%;
      background-color: $primary-color !important;
      color: #fff;
      font-family: $font-body;
    }
    .btn-createEn {
      text-align: center;
      width: 100%;
      background-color: $primary-color !important;
      color: #fff;
      font-family: $font-title;
    }
  }
}

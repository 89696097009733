@import "../../../themes.scss";

.cus-statistic-chart {
  background-color: white;
  padding: 25px;
  border-radius: 10px;

  .title {
    align-items: center;
    justify-content: space-evenly;
    font-size: $font-Size-title;
    font-weight: bold;
    color: $primary-color;
    font-family: $font-body;
  }
  .title-en {
    align-items: center;
    justify-content: space-evenly;
    font-size: $font-Size-title;
    font-weight: bold;
    color: $primary-color;
    font-family: $font-title;
  }
}

// -----------Color
$primary-color: #38b14a;
$secondary-color: #f5ac1d;
$background-table-active: #f8f9f9;

//font
$font-title: "Century Gothic";
$font-body: "Siemreap" !important;
$font-kh-bayon: "Bayon" !important;

$font-color: #38b14a;
//font-size
$font-Size-title: 22px;
$font-Size-head: 18px;
$font-Size-Subtitle: 15px;
$font-Size-body: 13px;
// Login Page

@mixin desktop {
  @media (min-width: #{1025px}) and (max-width: #{1650px}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{769px}) and (max-width: #{1024px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{426px}) and (max-width: #{768px}) {
    @content;
  }
}

@mixin mobile-large {
  @media (min-width: #{426px}) and (max-width: #{600px}) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: #{377px}) and (max-width: #{425px}) {
    @content;
  }
}

@mixin mobile-small {
  @media (min-width : #{0px}) and (max-width: #{376px}) {
    @content;
  }
}

@import "../../../themes.scss";

.reference-info {
  margin-top: 10px;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;

  .btn-addKh {
    border-radius: 10px;
    background-color: $primary-color !important;
    padding: 4px 20px;
    color: #fff;
    font-family: $font-body;
  }

  .btn-addEn {
    border-radius: 10px;
    background-color: $primary-color !important;
    padding: 4px 20px;
    color: #fff;
    font-family: $font-title;
  }

  .reference-list {
    background-color: $background-table-active;
    border-radius: 10px;
    padding: 10px;
    .profile {
      .image {
        width: auto;
        height: 140px;
      }
    }
  }
}

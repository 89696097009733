@import "../../../themes.scss";

.print-loan-approve {
  .dialog-titleKh {
    color: $primary-color;
    font-size: $font-Size-title;
    font-family: $font-body;
    font-weight: bold;
  }

  .dialog-titleEn {
    color: $primary-color !important;
    font-size: $font-Size-title !important;
    font-family: $font-title !important;
    font-weight: bold !important;
  }

  .close-icon {
    color: red;
  }

  .box-action {
    width: 100%;
    padding: 0px 18px 16px 18px;
    .btn-print {
      text-align: center;
      width: 100%;
      background-color: $primary-color !important;
      color: #fff;
    }
  }
}

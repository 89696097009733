@import "../../../themes.scss";

.update-customer {
  .MuiPaper-root {
    border-radius: 12px !important;
    width: auto !important;
  }

  .linkKh {
    font-family: $font-body;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
    text-decoration: none;
  }

  .linkEn {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
    text-decoration: none;
  }

  .titleKh {
    font-family: $font-body;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
    text-align: left;
    padding: 0px;
  }

  .titleEn {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
    text-align: left;
    padding: 0px;
  }

  .slash {
    width: 4px;
    height: 100%;
    background-color: $primary-color;
  }

  .btn-create-Kh {
    padding: 6px 20px;
    text-align: center;
    border-radius: 10px;
    background-color: $primary-color !important;
    color: #fff;
    font-family: $font-body;
  }
  .btn-create-En {
    padding: 6px 20px;
    text-align: center;
    border-radius: 10px;
    background-color: $primary-color !important;
    color: #fff;
    font-family: $font-title;
  }

  .body-container {
    margin-top: 20px;
    border-radius: 10px;
    background-color: #fff;
    padding: 30px;

    .btn-addKh {
      border-radius: 10px;
      background-color: $primary-color !important;
      padding: 6px 15px;
      color: #fff;
      font-family: $font-body;
    }

    .btn-addEn {
      border-radius: 10px;
      background-color: $primary-color !important;
      padding: 6px 15px;
      color: #fff;
      font-family: $font-title;
    }

    .body-titleKh {
      color: $primary-color;
      font-weight: bold;
      font-size: $font-Size-head;
      font-family: $font-body;
    }

    .body-titleEn {
      color: $primary-color;
      font-weight: bold;
      font-size: $font-Size-head;
      font-family: $font-title;
    }

    .body-textKh {
      font-family: $font-body;
      font-size: $font-Size-Subtitle;
    }

    .body-textEn {
      font-family: $font-title;
      font-size: $font-Size-Subtitle;
    }

    .text-field {
      width: 50%;
      background-color: #fff;
      border-radius: 10px;
      .MuiOutlinedInput-input {
        color: black !important;
        font-family: $font-body;
        border-color: #fff;
        background-color: $background-table-active;
      }
      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        color: black;
        border: 1px solid #fff !important;
      }
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
        color: black;
        border-color: #fff;
      }
      .MuiInputLabel-root.Mui-focused {
        color: black;
        font-family: $font-body;
      }
      // date picker
      .css-jvf6rl-MuiInputBase-root-MuiOutlinedInput-root {
        background-color: $background-table-active;
      }
    }

    .title-add {
      align-items: center;
      justify-content: space-evenly;
      color: $primary-color;
      font-family: $font-title;
      font-weight: bold;

      .profile {
        cursor: pointer;
        .avater-image {
          width: 140px;
          height: 140px;
        }
      }
      .text-Kh {
        font-family: $font-body;
        font-size: $font-Size-Subtitle;
        font-weight: bold;
        color: #000;
      }

      .text-En {
        font-family: $font-title;
        font-size: $font-Size-Subtitle;
        font-weight: bold;
        color: #000;
      }
    }
  }
}

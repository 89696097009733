@import "../themes.scss";

.setup-report {
  .slash {
    width: 4px;
    height: 50px;
    background-color: $primary-color;
  }
  .titleKh {
    font-family: $font-body;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
  }

  .titleEn {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
  }

  .btn-prinKh {
    border-radius: 10px;
    background-color: $primary-color !important;
    padding: 8px 20px;
    color: #fff;
    font-family: $font-body;
  }

  .btn-prinEn {
    border-radius: 10px;
    background-color: $primary-color !important;
    padding: 8px 20px;
    color: #fff;
    font-family: $font-title;
  }

  .box-marker {
    margin-top: 4px;
    .contentKh {
      font-weight: bold;
      font-family: $font-body;
      font-size: $font-Size-Subtitle;
    }

    .contentEn {
      font-weight: bold;
      font-family: $font-title;
      font-size: $font-Size-Subtitle;
    }

    .text-field {
      background-color: #fff;
      border-radius: 10px;

      .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
        font-family: $font-body;
      }

      .MuiOutlinedInput-input {
        border-radius: 10px;
        font-family: $font-body;
      }

      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
        font-family: $font-body;
        border-radius: 10px;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
        color: black;
        font-family: $font-body;
        border-radius: 10px;
      }

      .MuiInputLabel-root.Mui-focused {
        color: #fff;
        font-family: $font-body;
        border-radius: 10px;
      }
    }
  }

  .box-print-container {
    width: 100%;
    display: flex;
    justify-content: right;
  }

  .container {
    width: 100%;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 10px;
  }
}

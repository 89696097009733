@import "../../../themes.scss";

.create-loantype {
  .MuiPaper-root {
    border-radius: 12px !important;
    width: 500px !important;
  }

  .dialog-titleKh {
    color: $primary-color;
    font-size: $font-Size-title;
    font-family: $font-body;
    font-weight: bold;
  }

  .dialog-titleEn {
    color: $primary-color;
    font-size: $font-Size-title;
    font-family: $font-title;
    font-weight: bold;
  }
  .close-icon {
    color: red;
  }
  .headKh {
    font-size: $font-Size-Subtitle;
    color: #000;
    font-family: $font-body;
  }

  .headEn {
    font-size: $font-Size-Subtitle;
    color: #000;
    font-family: $font-title;
  }

  .action {
    padding: 0px 16px 16px 16px;
    width: 100%;

    .btn-Kh {
      width: 100%;
      background-color: $primary-color;
      color: #fff;
      font-family: $font-body;
    }

    .btn-En {
      width: 100%;
      background-color: $primary-color;
      color: #fff;
      font-family: $font-title;
    }
  }
}

@import "../../themes.scss";

.empty-data-dialog {
  background-color: #fff;
  height: 400px !important;

  .titleKh {
    padding: 10px;
    border: none;
    font-family: $font-body;
    font-size: $font-Size-head;
    font-weight: bold;
    text-align: center;
    color: $primary-color;
  }

  .titleEn {
    padding: 10px;
    border: none;
    font-family: $font-title;
    font-size: $font-Size-head;
    font-weight: bold;
    text-align: center;
    color: $primary-color;
  }

  .subKh {
    font-family: $font-body;
    font-size: $font-Size-Subtitle;
  }

  .subEn {
    font-family: $font-title;
    font-size: $font-Size-Subtitle;
  }
}

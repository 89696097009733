@import "../../../themes.scss";

.action {
  .icon-menu-delete {
    color: red;
  }
  .icon-menu-update {
    color: $primary-color;
  }
  .text-menuKh {
    font-size: $font-Size-Subtitle;
    font-family: $font-body;
  }

  .text-menuEn {
    font-size: $font-Size-Subtitle;
    font-family: $font-title;
  }
}
.three-point {
  font-weight: bold;
  color: #000;
}

@import "../themes.scss";

.preview-pdf {

  .containter {
    display: flex;
    justify-content: center;
    background-color: $background-table-active;
    width: 100%;
  }
}

@import "../../../themes.scss";

.card-installment-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;

  .installment-title {
    font-family: $font-title;
    font-size: 22px;
    color: #DF5B2E;
    font-weight: bold;
  }

  .card-box-total-installment {
    background-color: #DF5B2E;
    padding: 15px;
    border-radius: 10px;

    .title-txt {
      color: white;
      font-family: $font-title;
      font-size: 18px;
      font-weight: bold;
    }

    .icon-box-in {
      background-color: #E28D70;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      .ics-in {
        width: 22px;
        height: 22px;
        color: #fff;
      }
    }

    .sub-txt {
      font-family: $font-title;
      color: #fff;
      font-size: 13px;
    }
    .amount-txt {
      margin-top: -3px;
      font-family: $font-title;
      color: #fff;
      font-size: 30px;
    }
  }

  .card-box-total-installment-out {
    background-color: #D0533D;
    padding: 15px;
    border-radius: 10px;

    .title-txt {
      color: white;
      font-family: $font-title;
      font-size: 18px;
      font-weight: bold;
    }

    .icon-box-in {
      background-color: #DC8677;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      .ics-in {
        width: 22px;
        height: 22px;
        color: #fff;
      }
    }

    .sub-txt {
      font-family: $font-title;
      color: #fff;
      font-size: 13px;
    }
    .amount-txt {
      margin-top: -3px;
      font-family: $font-title;
      color: #fff;
      font-size: 30px;
    }
  }
  .card-box-total-installment-in {
    background-color: #FE5C4C;
    padding: 15px;
    border-radius: 10px;

    .title-txt {
      color: white;
      font-family: $font-title;
      font-size: 18px;
      font-weight: bold;
    }

    .icon-box-in {
      background-color: #F68074;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      .ics-in {
        width: 22px;
        height: 22px;
        color: #fff;
      }
    }

    .sub-txt {
      font-family: $font-title;
      color: #fff;
      font-size: 13px;
    }
    .amount-txt {
      margin-top: -3px;
      font-family: $font-title;
      color: #fff;
      font-size: 30px;
    }
  }
}

@import "../../../themes.scss";

.card-loan-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;

  .icon-box {
    background-color: #3c64f6;
    width: 48px;
    height: 48px;
    border-radius: 10px;

    .ics {
      width: 28px;
      height: 28px;
      color: #fff;
    }
  }

  .loan-title {
    font-family: $font-title;
    font-size: 22px;
    color: #1540B0;
    font-weight: bold;
  }
  .loan-title-kh {
    font-family: $font-body;
    font-size: 22px;
    color: #1540B0;
    font-weight: bold;
  }
  .customer-txt{
    font-family: $font-title;
  }
  .customer-txt-kh{
    font-family: $font-body;
  }

  .card-box-total-principal {
    background-color: #c059cb;
    padding: 15px;
    border-radius: 10px;
    // background-image: url('../../../Assets/khorm.png');
    // background-size: 100%;
    // background-repeat: no-repeat;
    // background-position: center;

    .title-txt {
      color: white;
      font-family: $font-title;
      font-size: 18px;
      font-weight: bold;
    }
    .title-txt-kh {
      color: white;
      font-family: $font-body;
      font-size: 18px;
      font-weight: bold;
    }

    .icon-box-in {
      background-color: #D987E1;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      .ics-in {
        width: 22px;
        height: 22px;
        color: #fff;
      }
    }

    .sub-txt {
      font-family: $font-title;
      color: #fff;
      font-size: 13px;
    }
    .sub-txt-kh {
      font-family: $font-body;
      color: #fff;
      font-size: 13px;
    }
    .amount-txt {
      margin-top: -3px;
      font-family: $font-title;
      color: #fff;
      font-size: 30px;
    }
  }

  .card-box-total-cash-out {
    background-color: #797ECB;
    padding: 15px;
    border-radius: 10px;

    .title-txt {
      color: white;
      font-family: $font-title;
      font-size: 18px;
      font-weight: bold;
    }
    .title-txt-kh {
      color: white;
      font-family: $font-body;
      font-size: 18px;
      font-weight: bold;
    }

    .icon-box-in {
      background-color: #9A9EDE;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      .ics-in {
        width: 22px;
        height: 22px;
        color: #fff;
      }
    }

    .sub-txt {
      font-family: $font-title;
      color: #fff;
      font-size: 13px;
    }
    .sub-txt-kh {
      font-family: $font-body;
      color: #fff;
      font-size: 13px;
    }
    .amount-txt {
      margin-top: -3px;
      font-family: $font-title;
      color: #fff;
      font-size: 30px;
    }
  }
  .card-box-total-cash-in {
    background-color: #8C5582;
    padding: 15px;
    border-radius: 10px;

    .title-txt {
      color: white;
      font-family: $font-title;
      font-size: 18px;
      font-weight: bold;
    }
    .title-txt-kh {
      color: white;
      font-family: $font-body;
      font-size: 18px;
      font-weight: bold;
    }

    .icon-box-in {
      background-color: #AB88A5;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      .ics-in {
        width: 22px;
        height: 22px;
        color: #fff;
      }
    }

    .sub-txt {
      font-family: $font-title;
      color: #fff;
      font-size: 13px;
    }
    .sub-txt-kh {
      font-family: $font-body;
      color: #fff;
      font-size: 13px;
    }
    .amount-txt {
      margin-top: -3px;
      font-family: $font-title;
      color: #fff;
      font-size: 30px;
    }
  }

  .card-box-total-cash-in {
    background-color: #8C5582;
    padding: 15px;
    border-radius: 10px;

    .title-txt {
      color: white;
      font-family: $font-title;
      font-size: 18px;
      font-weight: bold;
    }

    .icon-box-in {
      background-color: #AB88A5;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      .ics-in {
        width: 22px;
        height: 22px;
        color: #fff;
      }
    }

    .sub-txt {
      font-family: $font-title;
      color: #fff;
      font-size: 13px;
    }
    .amount-txt {
      margin-top: -3px;
      font-family: $font-title;
      color: #fff;
      font-size: 30px;
    }
  }

  .card-box-current-total {
    background-color: #03bdaa;
    padding: 15px;
    border-radius: 10px;

    .title-txt {
      color: white;
      font-family: $font-title;
      font-size: 18px;
      font-weight: bold;
    }
    .title-txt-kh {
      color: white;
      font-family: $font-body;
      font-size: 18px;
      font-weight: bold;
    }

    .icon-box-in {
      background-color: #7AE1D6;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      .ics-in {
        width: 22px;
        height: 22px;
        color: #fff;
      }
    }

    .sub-txt {
      font-family: $font-title;
      color: #fff;
      font-size: 13px;
    }
    .sub-txt-kh {
      font-family: $font-body;
      color: #fff;
      font-size: 13px;
    }
    .amount-txt {
      margin-top: -3px;
      font-family: $font-title;
      color: #fff;
      font-size: 30px;
    }
  }


  .card-box-total-cash-out-td {
    background-color: #6AB588;
    padding: 15px;
    border-radius: 10px;

    .title-txt {
      color: white;
      font-family: $font-title;
      font-size: 18px;
      font-weight: bold;
    }
    .title-txt-kh {
      color: white;
      font-family: $font-body;
      font-size: 18px;
      font-weight: bold;
    }

    .icon-box-in {
      background-color: #A8E1BF;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      .ics-in {
        width: 22px;
        height: 22px;
        color: #fff;
      }
    }

    .sub-txt {
      font-family: $font-title;
      color: #fff;
      font-size: 13px;
    }
    .sub-txt-kh {
      font-family: $font-body;
      color: #fff;
      font-size: 13px;
    }
    .amount-txt {
      margin-top: -3px;
      font-family: $font-title;
      color: #fff;
      font-size: 30px;
    }
  }
  .card-box-total-cash-in-td {
    background-color: #5CB2B0;
    padding: 15px;
    border-radius: 10px;

    
    .title-txt {
      color: white;
      font-family: $font-title;
      font-size: 18px;
      font-weight: bold;
    }
    .title-txt-kh {
      color: white;
      font-family: $font-body;
      font-size: 18px;
      font-weight: bold;
    }
    .icon-box-in {
      background-color: #9CDAD8;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      .ics-in {
        width: 22px;
        height: 22px;
        color: #fff;
      }
    }

    .sub-txt {
      font-family: $font-title;
      color: #fff;
      font-size: 13px;
    }
    .sub-txt-kh {
      font-family: $font-body;
      color: #fff;
      font-size: 13px;
    }
    .amount-txt {
      margin-top: -3px;
      font-family: $font-title;
      color: #fff;
      font-size: 30px;
    }
  }
}

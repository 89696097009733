@import "../../../themes.scss";

.card-pawn-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;

  .pawn-title {
    font-family: $font-title;
    font-size: 22px;
    color: #39a19f;
    font-weight: bold;
  }

  .card-box-total-paw {
    background-color: #5CB2B0;
    padding: 15px;
    border-radius: 10px;

    .title-txt {
      color: white;
      font-family: $font-title;
      font-size: 18px;
      font-weight: bold;
    }

    .icon-box-in {
      background-color: #9CDAD8;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      .ics-in {
        width: 22px;
        height: 22px;
        color: #fff;
      }
    }

    .sub-txt {
      font-family: $font-title;
      color: #fff;
      font-size: 13px;
    }
    .amount-txt {
      margin-top: -3px;
      font-family: $font-title;
      color: #fff;
      font-size: 30px;
    }
  }

  .card-box-total-paw-out {
    background-color: #6AB588;
    padding: 15px;
    border-radius: 10px;

    .title-txt {
      color: white;
      font-family: $font-title;
      font-size: 18px;
      font-weight: bold;
    }

    .icon-box-in {
      background-color: #A8E1BF;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      .ics-in {
        width: 22px;
        height: 22px;
        color: #fff;
      }
    }

    .sub-txt {
      font-family: $font-title;
      color: #fff;
      font-size: 13px;
    }
    .amount-txt {
      margin-top: -3px;
      font-family: $font-title;
      color: #fff;
      font-size: 30px;
    }
  }
  .card-box-total-paw-in {
    background-color: #80B1D3;
    padding: 15px;
    border-radius: 10px;

    .title-txt {
      color: white;
      font-family: $font-title;
      font-size: 18px;
      font-weight: bold;
    }

    .icon-box-in {
      background-color: #9FC7E3;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      .ics-in {
        width: 22px;
        height: 22px;
        color: #fff;
      }
    }

    .sub-txt {
      font-family: $font-title;
      color: #fff;
      font-size: 13px;
    }
    .amount-txt {
      margin-top: -3px;
      font-family: $font-title;
      color: #fff;
      font-size: 30px;
    }
  }
}

@import "../../../themes.scss";

.setup-dialog-delete {
  .MuiPaper-root {
    border-radius: 12px !important;
    width: 400px !important;
  }

  .titleKh {
    font-family: $font-body;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
  }

  .titleEn {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-Size-title;
    font-weight: bold;
  }

  .dialog-titleKh {
    color: $primary-color;
    font-size: $font-Size-title;
    font-family: $font-body;
    font-weight: bold;
  }

  .dialog-titleEn {
    color: $primary-color;
    font-size: $font-Size-title;
    font-family: $font-title;
    font-weight: bold;
  }

  .clear-icon {
    color: red;
  }

  .simple-text {
    font-size: $font-Size-Subtitle;
  }
  .body-text {
    color: $primary-color;
    font-size: $font-Size-Subtitle;
    font-weight: bold;
  }

  .action {
    padding-bottom: 20px;
    padding-left: 18px;
    padding-right: 18px;

    .btn-deleteKh {
      font-size: $font-Size-Subtitle;
      font-weight: bold;
      font-family: $font-body;
    }

    .btn-deleteEn {
      font-size: $font-Size-Subtitle;
      font-weight: bold;
      font-family: $font-title;
    }

    .btn-voidKh {
      background-color: red;
      color: white;
      border: none;
      font-weight: bold;
      font-family: $font-body;
    }

    .btn-voidEn {
      background-color: red;
      color: white;
      border: none;
      font-weight: bold;
      font-family: $font-title;
    }
  }

  .text-input {
    .MuiOutlinedInput-input {
      color: $primary-color;
      font-weight: bold;
      font-size: $font-Size-Subtitle;
    }
  }
}
